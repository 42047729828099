<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-img height="250px" src="/hausKim.jp2"></v-img>
          <v-card-title class="text-h4" style="word-wrap: normal">
            Herzlich Willkommen
          </v-card-title>
          <v-card-text class="text-justify">
            Oder wie man bei uns im Norden sagt: Moin. Nach der Kernsanierung
            vom Haus Kim in Wenningstedt/Sylt freuen wir uns, Ihnen jetzt zwei,
            von Grund auf renovierte, neu gestaltete 2-Zimmer-Ferienwohnungen
            für Ihren Urlaub auf Sylt anbieten zu können. Jeweils für 2 Pers.,
            ca. 45 qm groß, im Obergeschoss gelegen, mit Balkon, auf einer
            Ebene. Gemütlich und hell eingerichtet, so dass Sie sich wohlfühlen
            werden. Beides sind Nichtraucherwohnungen und auch für den längeren
            Aufenthalt geeignet. Unser Haus liegt ruhig und doch zentral in
            einer Seitenstraße. Bushaltestelle, Strand, Einkaufsmöglichkeiten
            und vieles, was für den täglichen Bedarf benötigt wird, ist
            fußläufig erreichbar. Stellmöglichkeiten sind sowohl für Ihr Auto,
            als auch für Ihre Fahrräder, dann überdacht, auf dem Grundstück
            vorhanden. Tiere sind bei uns nicht gestattet. Sollten wir jetzt Ihr
            Interesse geweckt haben, sehen Sie sich unsere Bildergallerie der
            beiden Wohnungen an. Wenn Sie dann Lust bekommen haben, Ihren
            Sylturlaub bei uns zu verbringen, rufen Sie uns an unter
            <strong>04651/29486</strong>
            oder schreiben Sie eine Mail an
            <a class="accent--text" href="mailto:fam-mannstedt@t-online.de"
              >fam-mannstedt@t-online.de</a
            >. Wir freuen uns, Sie in unserem privat geführten Haus begrüßen zu
            dürfen.
            <br />
            <br />
            Anja und Haakon Mannstedt
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <appartment-card
          appName="Sabrina"
          appLink="ferienwohnung_sabrina"
          appImagePath="app2/Wohnzimmer-1.jp2"
        >
        </appartment-card>
      </v-col>
      <v-col cols="12" md="6">
        <appartment-card
          appName="Svenja"
          appLink="ferienwohnung_svenja"
          appImagePath="app1/Wohnzimmer-1.jp2"
        >
        </appartment-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title class="text-h4"> So finden Sie uns </v-card-title>
          <v-card-text>
            <iframe
              v-if="cookiesAccepted"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1021.0527302238321!2d8.326862376205304!3d54.934758192926544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b4deae1089aad1%3A0x222dbc634956a784!2sNormannenweg+9%2C+25996+Wenningstedt-Braderup+(Sylt)!5e0!3m2!1sde!2sde!4v1491142208971"
              style="border: 0"
              allowfullscreen="allowfullscreen"
              width="100%"
              height="350px"
              frameborder="0"
            >
            </iframe>
            <v-card-text
              v-else-if="cookiesAccepted === false"
              class="text-justify"
            >
              Sie haben die Cookies für Google Maps abgelehnt. <br />
              Nachdem Sie die Cookies für Google Maps akzeptieren, können Sie
              hier den Standort von Haus Kim sehen.
              <v-switch
                v-model="cookieSwitch"
                label="Cookies für Google Maps akzeptieren"
              ></v-switch>
            </v-card-text>
            <v-card-text v-else class="text-justify">
              Nachdem Sie die Cookies für Google Maps akzeptieren, können Sie
              hier den Standort von Haus Kim sehen.
            </v-card-text>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import appartmentCardVue from '../components/app-overview-card.vue'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  data () {
    return {
      cookieSwitch: false
    }
  },
  computed: {
    ...mapState({
      cookiesAccepted: state => state.cookiesAccepted
    })
  },
  components: {
    'appartment-card': appartmentCardVue
  },
  watch: {
    cookieSwitch (val) {
      this.$store.dispatch('setCookiesAccepted', val)
    }
  }
}
</script>
