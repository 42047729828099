<template>
  <v-card>
    <v-img max-height="250px" :src="appImagePath"> </v-img>
    <v-card-title class="text-h4">Ferienwohnung {{ appName }}</v-card-title>
    <v-card-actions>
      <v-row>
        <v-col cols="12" md="4" class="text-center">
          <v-btn
            text
            class="text-capitalize"
            color="primary"
            :to="'/' + appLink"
          >
            Mehr Infos
          </v-btn>
        </v-col>
        <v-col cols="12" md="4" class="text-center">
          <v-btn
            text
            class="text-capitalize"
            color="primary"
            :to="'/' + appLink + '#Galerie'"
          >
            Galerie
          </v-btn>
        </v-col>
        <v-col cols="12" md="4" class="text-center">
          <v-btn
            text
            class="text-capitalize"
            color="primary"
            :to="'/' + appLink + '#Preise'"
          >
            Preise
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'app-overview-card',
  data () {
    return {}
  },
  props: {
    appName: String,
    appLink: String,
    appImagePath: String
  },
  mounted () {
    // From testing, without a brief timeout, it won't work.
    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), 1)
    }
  },
  methods: {
    scrollTo: function (hashtag) {
      setTimeout(() => { location.href = hashtag }, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
